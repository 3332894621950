import JwtService from "@/core/services/jwt.service";
import store from "./index";
import { SELECT_CUSTOMER, UPDATE_CUSTOMERS } from "./customer.module";

// action types
export const LOGIN = "login";
export const LOGOUT = "logout";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  [LOGIN](context, user) {
    context.commit(SET_AUTH, user);
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
};

const mutations = {
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
  },

  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    JwtService.destroyToken();
    store.dispatch(SELECT_CUSTOMER, {});
    store.dispatch(UPDATE_CUSTOMERS, []);
    window.location.href = `https://auth.inabex.net/loginV2/login?backurl=${location.host}/token&logout=true`;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
