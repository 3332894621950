import axios from "axios";
import JwtService from "@/core/services/jwt.service";
import store from "./store";
import { LOGOUT } from "@/core/services/store/auth.module";

/**
 * Service to call HTTP request via Axios
 */
var instance;
var url = "https://api.inabex.online";

const BackofficeApiService = {
  serverURL: url,
  baseURL: `${url}/api/`,
  init() {
    instance = axios.create();
    instance.defaults.baseURL = this.baseURL;
    instance.interceptors.request.use(function(config) {
      var customer = store.getters["currentCustomer"];
      if (customer) {
        config.headers.CustomerCode = customer.CustomerCode;
      }
      config.headers.Authorization = `Bearer ${JwtService.getToken()}`;
      return config;
    });

    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const { status } = error.response;
        if (status === 401) {
          store.dispatch(LOGOUT);
        }
        return Promise.reject(error);
      }
    );
  },

  query(resource, params) {
    return instance.get(resource, params);
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource) {
    return instance.get(`${resource}`);
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return instance.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return instance.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return instance.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return instance.delete(resource);
  },
};

export default BackofficeApiService;
