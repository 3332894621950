// action types
export const SELECT_CUSTOMER = "selectCustomer";
export const UPDATE_CUSTOMERS = "updateCustomers";

export const SELECT_SUBSCRIPTION = "selectSubscription";
export const UPDATE_SUBSCRIPTIONS = "updateSubscriptions";

// mutation types
export const SET_CUSTOMERS = "setCustomers";
export const SET_CUSTOMER = "setCustomer";

export const SET_SUBSCRIPTIONS = "setSubscriptions";
export const SET_SUBSCRIPTION = "setSubscription";

const state = {
  customer: {},
  customers: [],
  subscription: {},
  subscriptions: []
};

const getters = {
  currentCustomer(state) {
    return state.customer;
  },
  customers(state) {
    return state.customers;
  },
  currentSubscription(state) {
    return state.subscription;
  },
  subscriptions(state) {
    return state.subscriptions;
  },
};

const actions = {
  [SELECT_CUSTOMER](context, customer) {
    context.commit(SET_CUSTOMER, customer);
  },
  [UPDATE_CUSTOMERS](context, customers) {
    context.commit(SET_CUSTOMERS, customers);
  },

  [SELECT_SUBSCRIPTION](context, sub) {
    context.commit(SET_SUBSCRIPTION, sub);
  },
  [UPDATE_SUBSCRIPTIONS](context, subs) {
    context.commit(SET_SUBSCRIPTIONS, subs);
  },
};

const mutations = {
  [SET_CUSTOMER](state, customer) {
    state.customer = customer;
  },
  [SET_CUSTOMERS](state, customers) {
    state.customers = customers;
  },
  [SET_SUBSCRIPTION](state, sub) {
    state.subscription = sub;
  },
  [SET_SUBSCRIPTIONS](state, subs) {
    state.subscriptions = subs;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
