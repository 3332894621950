import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import BackofficeApiService from "./core/services/backofficeapi.service";
import ArreniaApiService from "./core/services/arreniaapi.service";

//import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { LOGOUT } from "@/core/services/store/auth.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";

// Devextreme
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

// API service init
ApiService.init();

// backoffice API service init
BackofficeApiService.init();

// Arrenia Api service init
ArreniaApiService.init();

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  //var currentCustomer = store.getters["currentCustomer"];
  //var currentSubscription = store.getters["currentSubscription"];
  var isAuthenticated = store.getters["isAuthenticated"];
  
  if (to.meta.authRequired && !isAuthenticated)
  store.dispatch(LOGOUT);
  else next();

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
