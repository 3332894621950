import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
          meta: {
            authRequired: true,
          },
        },
        {
          path: "/pos",
          name: "pos",
          component: () => import("@/view/pages/Pos/Pos.vue"),
          children: [
            {
              path: "detail/:guid",
              name: "pos-detail",
              component: () => import("@/view/pages/Pos/Detail.vue"),
              meta: {
                authRequired: true,
              },
            },
          ],
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "customers",
          path: "/customers",
          component: () => import("@/view/pages/auth/Customers"),
          meta: {
            authRequired: true,
          },
        },
        {
          name: "subscriptions",
          path: "/subscriptions",
          component: () => import("@/view/pages/auth/Subscriptions"),
          meta: {
            authRequired: true,
          },
        },
        {
          name: "token",
          path: "/token",
          component: () => import("@/view/pages/auth/Token"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/dashboard",
    },
  ],
});
